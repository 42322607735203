// Plugin CSS
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~animate.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// Init main SCSS File
@import "/public/assets/scss/style.scss";

:root {
    --bg-dark: #0F1A29;
    --bg-white: #F8F9FC;
}
